/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import QuizQuestionsRate from "./QuizQuestionsRate";
import QuizOptions from "./QuizOptions";
import "./QuizQuestionComponent.css";
import StatementFormatter from "./components/StatementFormatter";
import ReactGA from "react-ga4";
ReactGA.initialize("G-EDEXTHSM4C");

import { Helmet } from "react-helmet";

const defaultProps = {
  text: "1. Who was the first President of India?",
};

const QuizQuestionComponent = (props) => {
  const [fontSize, setFontSize] = useState(36);
  const questionRef = useRef(null);
  const [options, setOptions] = useState([]);

  function getIndexToLetter(index) {
    const letters = ["a", "b", "c", "d"];
    return letters[index];
  }
  useEffect(() => {
    if (!questionRef.current) return;

    const { lineHeight, height } = getComputedStyle(questionRef.current);
    const numLines = Math.ceil(parseInt(height, 10) / parseInt(lineHeight, 10));
    // Set font size based on number of lines within the given range
    setFontSize(Math.min(Math.max(20, 26 - (numLines - 1)), 26));
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <title>SAMAI-{props.data.question_text} Pervious Year Question Paper</title> */}

        <meta name="description" content={props.data.question_text} />
        <meta
          name="keywords"
          content="AI-powered mock test, Online mock test for UPSC, Free UPSC AI mock test, AI-based mock test for UPSC, UPSC prelims mock test, Civil service exam mock test, AI-powered UPSC practice tests, UPSC online mock test, AI mock test for UPSC prelims, AI mock test for UPSC mains, UPSC AI practice tests, UPSC adaptive learning mock test, UPSC AI test series, UPSC mock test with AI analysis, Personalized AI mock test UPSC, AI evaluate performance UPSC mock tests, AI-driven mock test for UPSC, AI-powered UPSC test series, UPSC AI test analysis, Best AI mock test platform for UPSC, Top AI mock test providers for UPSC, AI-powered practice tests for UPSC, IAS mock test with AI feedback, UPSC full-length AI mock tests, AI mock tests for working professionals UPSC, AI mock test with personalized feedback UPSC, Best AI mock test for UPSC prelims, AI mock test reviews UPSC, Best AI mock test in India, AI mock test for UPSC aspirants, AI mock test performance analysis UPSC, AI-based UPSC prelims test series, Free online AI mock test for UPSC, AI mock tests for UPSC preparation, UPSC AI mock test feedback, AI-based UPSC exam preparation, AI-powered UPSC exam simulator, UPSC AI-driven test series, AI mock test for civil services, AI mock test preparation UPSC"
        />
        <link
          rel="canonical"
          href="https://samai.scontiapp.com/upsc-kpsc-online-mocktest/TakeQuiz"
        />
        <meta name="description" content={props.data.question_text} />
        {/* */}
        <meta property="og:description" content={props.data.question_text} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://samai.scontiapp.com/upsc-kpsc-online-mocktest/TakeQuiz"
        />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
        <link
          rel="canonical"
          href="https://samai.scontiapp.com/upsc-kpsc-online-mocktest/TakeQuiz"
        />
      </Helmet>

      <div className="question-container" style={{ marginTop: 8 }}>
        {/* <div className="rate-container">
        <QuizQuestionsRate />
      </div> */}

        <div className="question-text-container-normal">
          <h4>Q{props.index + 1}. </h4>
          <div className="question-text" ref={questionRef}>
            {/* {props.data.question_text} */}
            <StatementFormatter text={props.data.question_text} />
            {props?.data?.question_type && props?.data?.question_type !== "generated" ? (
              <div
                style={{
                  backgroundColor: "#000000",
                  borderRadius: "5px",
                  width: "60px",
                  textAlign: "center",
                  color: "#ffff00",
                  fontSize: "12px",
                  height: "30px",
                  fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
                }}
              >
                {`${props?.data?.question_type?.toUpperCase()} ${
                  props?.data?.year && props?.data?.question_type === "pyq" ? props?.data?.year : ""
                }`}
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className="options-container">
          <QuizOptions
            data={[
              props.data.option_a,
              props.data.option_b,
              props.data.option_c,
              props.data.option_d,
            ]}
            setResponses={props.setResponses}
            responses={props.responses}
            questiondata={props.data}
            qIndex={props.index}
          />
        </div>
        {/* <div className="rate-container-bottom">
        <QuizQuestionsRate />
      </div> */}
      </div>
    </>
  );
};

export default QuizQuestionComponent;
