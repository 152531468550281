import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography, CircularProgress, useMediaQuery } from "@mui/material";
import { Cancel } from "@mui/icons-material";

const WeeklyTest = () => {
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [alignment, setAlignment] = useState("week1");
  const [loading, setLoading] = useState(false);
  const [weekData, setWeekData] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const weeklyData = {
    week1: { topics: ["Math", "Science"], attended: true },
    week2: { topics: [], attended: false },
    week3: { topics: ["English", "History"], attended: true },
    week4: { topics: [], attended: false },
    week5: { topics: [], attended: false },
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setWeekData(weeklyData[alignment.toLowerCase()]);
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup timeout on component unmount or re-render
  }, [alignment]);

  const DrawerList = (
    <Box sx={{ width: 250, padding: 2 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">Select Month</Typography>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          <Cancel />
        </Button>
      </div>
      <List>
        {[
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ].map((month, index) => (
          <ListItem key={month} disablePadding>
            <ListItemButton
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
                border: selectedMonth === month ? "2px solid" : "none",
                // borderRadius: selectedMonth === month ? "20px" : "none",
                borderImage:
                  selectedMonth === month
                    ? "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%) 1"
                    : "none",
              }}
              onClick={() => handleMonthSelect(month)}
            >
              <Typography variant="body1" sx={{ width: 25 }}>{`${index + 1}`}</Typography>
              <Typography variant="body1">{month}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* <Box sx={{ p: 3 }}> */}
      <Typography variant="h4" mb={2} textAlign="center">
        Weekly Test
      </Typography>
      {/* </Box> */}
      <Box sx={{ padding: isSmallScreen ? 2 : 4 }}>
        <Button
          style={{
            marginTop: 25,
            // background: "linear-gradient(90deg, #ff8a00, #da1b60)",
            color: "#000",
          }}
          onClick={toggleDrawer(true)}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
        >
          {selectedMonth || "Select Month"}
        </Button>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        <Box sx={{ marginTop: 4 }}>
          <ToggleButtonGroup
            color="error"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{ flexWrap: "wrap", gap: 2 }}
          >
            {["Week1", "Week2", "Week3", "Week4", "Week5"].map((week, index) => (
              <ToggleButton
                key={index}
                value={week.toLowerCase()}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  borderRadius: 8,
                  background:
                    alignment === week.toLowerCase()
                      ? "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)"
                      : "transparent",

                  border: alignment === week.toLowerCase() ? "none" : "1px solid rgba(0,0,0,0.1)",
                  "&:hover": {
                    background:
                      alignment === week.toLowerCase()
                        ? "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)"
                        : "rgba(0,0,0,0.1)",
                  },
                }}
              >
                <Typography sx={{ color: alignment === week.toLowerCase() ? "#fff" : "#000" }}>
                  {week}
                </Typography>
                <Typography sx={{ color: alignment === week.toLowerCase() ? "#fff" : "#000" }}>
                  22-02-2024
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          {loading ? (
            <CircularProgress />
          ) : weekData?.topics.length ? (
            <>
              <Typography variant="h6">Topics for this week:</Typography>
              <ol>
                {weekData.topics.map((topic, index) => (
                  <li key={index + 1}>
                    <Typography>{topic}</Typography>
                  </li>
                ))}
              </ol>
              <Button
                variant="contained"
                sx={{
                  background: "linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%)",
                  transition: "all 0.3s ease",
                  color: "#fff",
                  marginTop: 2,
                }}
              >
                {weekData.attended ? "View Results" : "Start Test"}
              </Button>
            </>
          ) : (
            <Typography variant="h6">No test scheduled this week.</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default WeeklyTest;
