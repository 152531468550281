/* eslint-disable react/prop-types */
import DailyTest from "./componets/DailyTest";
import MonthlyTest from "./componets/MonthlyTest";
import WeeklyTest from "./componets/WeeklyTest";
import RetakeTest from "./componets/RetakeTest";

import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Container,
  Grid,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const data = [
  {
    id: 1,
    examType: "Daily Test",
    // background: "linear-gradient(to right, #ee9ca7, #ffdde1)",
    component: "daily",
  },
  {
    id: 2,
    examType: "Weekly test",
    // background: "linear-gradient(to right, #c6ffdd, #fbd786, #f7797d)",
    component: "weekly",
  },
  {
    id: 3,
    examType: "Monthly Test",
    // background: "linear-gradient(to right, #12c2e9, #c471ed, #f64f59)",
    component: "monthly",
  },
  {
    id: 4,
    examType: "Re-take Test",
    // background: "linear-gradient(to right, #f12711, #f5af19)",
    component: "retake",
  },
];

const StyledCard = styled(Card)(({ theme, ishover, background }) => ({
  maxWidth: ishover === "true" ? 250 : 245,
  width: ishover === "true" ? 250 : 245,
  height: ishover === "true" ? 160 : 150,
  maxHeight: ishover === "true" ? 160 : 150,
  background: background,
  boxShadow: theme.shadows[10],
  cursor: "pointer",
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.standard,
  }),
}));

const TypeCard = ({ item, index, setActiveComponent, isActive }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <Box sx={{ width: 250, height: 160 }}>
      <StyledCard
        ishover={isHover.toString()}
        background={item?.background}
        onClick={() => setActiveComponent(item.component)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        sx={{ border: isActive ? "3px solid #00a3c2" : "none" }}
      >
        <CardContent style={{ height: 100 }}>
          <Typography variant="h6">{item?.examType}</Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            sx={{ color: "#fff" }}
            variant="contained"
            endIcon={
              <KeyboardDoubleArrowRightIcon style={{ width: 18, height: 18, color: "#fff" }} />
            }
          >
            Start Test
          </Button>
        </CardActions>
      </StyledCard>
    </Box>
  );
};

const NeetMainLandingPage = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "daily":
        return <DailyTest />;
      case "weekly":
        return <WeeklyTest />;
      case "monthly":
        return <MonthlyTest />;
      case "retake":
        return <RetakeTest />;
      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Typography variant="h2" sx={{ textAlign: "center", mb: 2 }}>
          NEET Features
        </Typography>
        {/* <Box sx={{ mb: 4 }}>
          <Typography variant="h5" component="div" sx={{ 
            textAlign: 'center',
            animation: 'marquee 20s linear infinite',
            whiteSpace: 'nowrap',
            '@keyframes marquee': {
              '0%': { transform: 'translateX(100%)' },
              '100%': { transform: 'translateX(-100%)' }
            }
          }}>
            📢 Here you can see all the features related to NEET🚨
          </Typography>
        </Box> */}

        <Grid container spacing={2} justifyContent="center">
          {data.map((item, index) => (
            <Grid item key={item.id}>
              <TypeCard
                item={item}
                index={index}
                setActiveComponent={setActiveComponent}
                isActive={activeComponent === item.component}
              />
            </Grid>
          ))}
        </Grid>

        {activeComponent && (
          <Box sx={{ mt: 4 }}>
            <Divider sx={{ mb: 4 }} />
            {renderActiveComponent()}
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default NeetMainLandingPage;
