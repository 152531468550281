import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography, CircularProgress, useMediaQuery } from "@mui/material";
import { Cancel } from "@mui/icons-material";

const MonthlyTest = () => {
  const [open, setOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [monthData, setMonthData] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const weeklyData = {
    January: { topics: ["Math", "Science"], attended: true },
    February: { topics: [], attended: false },
    March: { topics: ["English", "History"], attended: true },
    April: { topics: [], attended: false },
    May: { topics: [], attended: false },
    June: { topics: [], attended: false },
    July: { topics: [], attended: false },
    August: { topics: ["Physics"], attended: false },
    September: { topics: [], attended: false },
    October: { topics: ["Chemistry"], attended: true },
    November: { topics: [], attended: false },
    December: { topics: [], attended: false },
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
    setOpen(false);
    setLoading(true);

    // Simulate data loading
    setTimeout(() => {
      setMonthData(weeklyData[month]);
      setLoading(false);
    }, 1000);
  };

  const DrawerList = (
    <Box sx={{ width: 250, padding: 2 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">Select Month</Typography>
        <Button onClick={() => setOpen(false)}>
          <Cancel />
        </Button>
      </div>
      <List>
        {Object.keys(weeklyData).map((month) => (
          <ListItem key={month} disablePadding>
            <ListItemButton
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 2,
                border: selectedMonth === month ? "2px solid" : "none",
                borderImage:
                  selectedMonth === month
                    ? "linear-gradient(to right, #DA22FF, #9733EE) 1"
                    : "none",
                borderRadius: "8px",
              }}
              onClick={() => handleMonthSelect(month)}
            >
              <Typography variant="body1">{month}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Typography variant="h4" mb={2} textAlign="center">
        Monthly Test
      </Typography>
      <Box sx={{ padding: isSmallScreen ? 2 : 4 }}>
        <Button
          style={{
            marginTop: 25,
            color: "#000",
          }}
          onClick={toggleDrawer(true)}
          endIcon={<KeyboardArrowDownIcon />}
          variant="outlined"
        >
          {selectedMonth || "Select Month"}
        </Button>
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>

        <Box sx={{ marginTop: 4 }}>
          {loading ? (
            <CircularProgress />
          ) : monthData ? (
            monthData.topics.length ? (
              <>
                <Typography variant="h6">Topics for this month:</Typography>
                <ol>
                  {monthData.topics.map((topic, index) => (
                    <li key={index}>
                      <Typography>{topic}</Typography>
                    </li>
                  ))}
                </ol>
                <Button
                  variant="contained"
                  sx={{
                    background: "linear-gradient(to right, #DA22FF, #9733EE)",
                    transition: "all 0.3s ease",
                    color: "#fff",
                    marginTop: 2,
                  }}
                >
                  {monthData.attended ? "View Results" : "Start Test"}
                </Button>
              </>
            ) : (
              <Typography variant="h6">No test scheduled this month.</Typography>
            )
          ) : (
            <Typography variant="h6">Please select a month to see the details.</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MonthlyTest;
