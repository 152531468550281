import React, { useState } from "react";
import ComingSoon from "../../Prelims/assets/ComingSoon.json";
import MDBox from "components/MDBox";
import FullScreenLottie from "layouts/quiz/FullScreenLottie";

const RetakeTest = () => {
  const [animation, setAnimation] = useState(ComingSoon);
  const [animationText, setAnimationText] = useState("Coming Soon");
  return (
    <>
      <div>
        <FullScreenLottie content={animationText} animation={animation} />
      </div>
    </>
  );
};

export default RetakeTest;
