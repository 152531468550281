/* eslint-disable react/prop-types */
// import React, { useState } from "react";
// import { Modal } from "react-bootstrap";
// import GoogleButton from "react-google-button";
// import { auth, provider } from "../../../firebase/firebase";
// import { useCookies } from "react-cookie";
// import { useNavigate } from "react-router-dom";
// import { client } from "constants/APIFunctions";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import brandWhite from "assets/images/applogo.png";
// import MDBox from "components/MDBox";
// import { Card, TextField, Button, Grid } from "@material-ui/core";
// import { InputAdornment } from "@material-ui/core";
// import { Email, LockRounded } from "@material-ui/icons";
// import Sconti from "./../../../Sconti.png";
// import {
//   ExpandLessRounded,
//   ExpandMoreRounded,
//   KeyOff,
//   KeySharp,
//   Password,
//   Phone,
// } from "@mui/icons-material";
// import ChipsArray from "layouts/authentication/screens/SelectExams";
// import SelectLanguage from "layouts/authentication/screens/SelectLanguage";
// const SignInModal = ({ show, handleClose }) => {
//   const [loggedIn, setLoggedIn] = useState(false);
//   const navigate = useNavigate();
//   const [cookies, setCookie] = useCookies(["user"]);
//   const [isShowForm, setIsShowForm] = useState(false);
//   const [userDetails, setUserDetails] = useState();

//   const handleGoogleSignIn = () => {
//     auth
//       .signInWithPopup(provider)
//       .then((res) => {
//         console.log(res);
//         toast("Login successful");
//         const userdata = {
//           user_id: res.user.uid,
//           user_name: res.additionalUserInfo.profile?.name,
//           user_email: res.additionalUserInfo.profile?.email,
//         };
//         getUserDetailsGoogle(userdata);
//         // navigate("/dashboard");
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   const saveUserDetails = () => {
//     var user = userDetails;
//     client
//       .post("/api/user/register", userDetails)
//       .then((response) => {
//         console.log(response);
//         navigate("/upsc-kpsc-online-mocktest");
//         toast.success("Registered Successfully...");
//         const objtostore = {
//           user_id: user.user_id,
//           fullname: user.full_name,
//           email: user.email,
//           phone_number: user.phone_number,
//           interested_exams: user.interested_exams,
//           user_type: "user",
//           interested_exams: userDetails.interested_exams,
//           preferred_language: userDetails?.selected_language,
//           preferred_language_code: userDetails?.selected_language_code,
//         };
//         console.log(objtostore);
//         setCookie("userdata", objtostore);
//         localStorage.setItem("userdata", JSON.stringify(objtostore));
//       })
//       .catch((err) => {
//         console.log(err);
//         // toast.error("An error occurred");
//       });
//   };

//   const getUserDetailsGoogle = (userdata) => {
//     client
//       .get("/api/user/user_profile", { params: { user_id: userdata.user_id } })
//       .then((res) => {
//         console.log(res);
//         if (res.data.detail) {
//           console.log(res.data.detail);
//           setIsShowForm(true);
//           const user = {
//             user_id: userdata.user_id,
//             full_name: userdata.user_name,
//             email: userdata.user_email,
//             password: "google@123",
//             phone_number: "N/A",
//             interested_exams: "N/A",
//             selected_language: "English",
//             selected_language_code: "en",
//           };
//           console.log(user);
//           setUserDetails(user);
//         } else {
//           console.log(res.data);
//           setCookie("userdata", res.data);
//           localStorage.setItem("userdata", JSON.stringify(res.data));
//           navigate("/upsc-kpsc-online-mocktest");
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   return (
//     <Modal show={show} onHide={handleClose} centered size="md">
//       <ToastContainer />
//       {isShowForm ? (
//         <div style={{ padding: 8 }}>
//           <Modal.Header closeButton>
//             <Modal.Title>
//               <span role="img" aria-label="lock-icon" style={{ marginRight: "5px" }}>
//                 🤖
//               </span>
//               Help us Personalize your Journey
//             </Modal.Title>
//           </Modal.Header>
//           <div style={{ flexDirection: "column", display: "flex", marginTop: "8px" }}>
//             <div
//               style={{
//                 color: "#000",
//                 fontSize: "18px",
//                 fontFamily: "Source Sans Pro",
//                 fontWeight: 500,
//                 lineHeight: "20px",
//                 marginBottom: "8px",
//               }}
//             >
//               Select Exams you are preparing for
//             </div>
//             <ChipsArray setExams={setUserDetails} exams={userDetails} />
//           </div>
//           <div style={{ flexDirection: "column", display: "flex", marginTop: "8px" }}>
//             <div
//               style={{
//                 color: "#000",
//                 fontSize: "18px",
//                 fontFamily: "Source Sans Pro",
//                 fontWeight: 500,
//                 lineHeight: "20px",
//                 marginBottom: "8px",
//               }}
//             >
//               Select your Preferred Language
//             </div>
//             <SelectLanguage setExams={setUserDetails} exams={userDetails} />
//           </div>
//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             color="primary"
//             style={{
//               width: "90%",
//               height: "42px",
//               padding: "0px 8px",
//               border: "0",
//               boxSizing: "border-box",
//               borderRadius: "24px",
//               backgroundColor: "#fff",
//               color: "#000",
//               fontSize: "20px",
//               fontFamily: "Source Sans Pro",
//               fontWeight: 700,
//               lineHeight: "39px",
//               outline: "none",
//               alignSelf: "center",
//               marginTop: "8px",
//               marginBottom: "8px",
//               marginLeft: "4%",
//             }}
//             onClick={() => {
//               if (userDetails?.interested_exams === "N/A") {
//                 toast.error("All fields are required...");
//               } else {
//                 saveUserDetails();
//               }
//             }}
//           >
//             Continue
//           </Button>
//         </div>
//       ) : (
//         <div>
//           <Modal.Header closeButton>
//             <Modal.Title>
//               <span role="img" aria-label="lock-icon" style={{ marginRight: "10px" }}>
//                 🔐
//               </span>{" "}
//               Sign In
//             </Modal.Title>
//           </Modal.Header>
//           <Modal.Body style={{ backgroundColor: "#fff", padding: "20px", borderRadius: "10px" }}>
//             <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
//               <MDBox
//                 component="img"
//                 src={Sconti}
//                 alt="Brand"
//                 width="5rem"
//                 style={{ alignSelf: "center" }}
//               />
//               <h4 style={{ textAlign: "center", marginTop: "8px" }}>SAMAI</h4>
//             </div>
//             {loggedIn ? (
//               <p style={{ fontSize: "18px", fontWeight: "bold" }}>Welcome! You are logged in.</p>
//             ) : (
//               <GoogleButton
//                 onClick={handleGoogleSignIn}
//                 style={{ width: "100%", marginTop: "20px" }}
//               />
//             )}
//           </Modal.Body>
//           <Modal.Footer>
//             <div
//               style={{
//                 fontSize: "14px",
//                 fontWeight: "bold",
//                 fontFamily: "sans-serif",
//                 textAlign: "center",
//                 width: "100%",
//               }}
//             >
//               By signing in you agree to accept the terms and conditions
//             </div>
//           </Modal.Footer>
//         </div>
//       )}
//     </Modal>
//   );
// };

// export default SignInModal;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import { Button, CircularProgress, TextField } from "@material-ui/core";
// import Sconti from "./../../../Sconti.png";
import SamAi from "./../../../SamAi.png";
import axios from "axios";
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const SignInModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailLogin = () => {
    if (!email || !password) {
      toast.error("Please enter both email and password.");
      return;
    }

    const payload = { email, password };
    console.log("entered");
    setLoading(true);

    try {
      axios
        .post("https://auth.scontiapp.com/auth/user/login", payload)
        .then((response) => {
          console.log("userdata", response);
          const userData = { ...response.data, examPreference: response.data.interested_exams[0] };
          console.log("userdatass", userData);
          toast.success("Login successful!");
          console.log("userdata", userData);
          setCookie("userdata", userData);
          localStorage.setItem("userdata", JSON.stringify(userData));
          setLoading(false);

          navigate("/upsc-kpsc-online-mocktest");
        })
        .catch((err) => {
          console.log("error", err);
          console.error(err);
          setLoading(false);

          toast.error("Invalid email or password. Please try again.");
        });
    } catch (error) {
      console.log("catch", error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <ToastContainer />
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            <span role="img" aria-label="lock-icon" style={{ marginRight: "10px" }}>
              🔐
            </span>
            Sign In
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#fff", padding: "20px", borderRadius: "10px" }}>
          <div style={{ justifyContent: "center", display: "flex", flexDirection: "column" }}>
            <MDBox
              component="img"
              src={SamAi}
              alt="Brand"
              width="5rem"
              style={{ alignSelf: "center" }}
            />
            <h4 style={{ textAlign: "center", marginTop: "8px" }}>SAMAI</h4>
          </div>
          <form style={{ marginTop: "20px" }}>
            <TextField
              label="Email"
              placeholder="example@gmail.com"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginBottom: "16px" }}
            />
            {/* <TextField
              // label="Password"
              placeholder="********"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ marginBottom: "16px" }}
            /> */}
            <FormControl sx={{ marginBottom: "16px" }} variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? "hide the password" : "display the password"}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              // disabled={loading}

              // size="large"
              onClick={handleEmailLogin}
              style={{
                width: "100%",
                height: "50px",
                padding: "0px 8px",
                border: "0",
                borderRadius: "16px",
                // background: "#4636FF",
                background: "linear-gradient(to right, #6A42C2  51%, #DA22FF  100%)",
                color: "#fff",
                fontSize: "18px",
                fontWeight: 700,
                marginTop: "8px",
              }}
            >
              {/* Sign In */}
              {/* <CircularProgress size={24} style={{color: "#fff"}} />  */}
              {loading ? <CircularProgress size={24} style={{ color: "#fff" }} /> : "Sign In"}
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "sans-serif",
              textAlign: "center",
              width: "100%",
            }}
          >
            By signing in you agree to accept the terms and conditions
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default SignInModal;
