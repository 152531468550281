import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  ArrowForwardIos,
  ArrowRightRounded,
  CloudDoneOutlined,
  DoubleArrow,
} from "@mui/icons-material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import image from "../assets/backgroundimage.png";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);
  React.useEffect(() => {
    console.log("cookies", cookies.userdata);
    if (cookies.userdata) {
      navigate("/upsc-kpsc-online-mocktest");
    }
  }, []);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundColor: "#000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <img
              src="https://mocktestsqgpt.web.app/static/media/applogo.6da704baf4bc5a358805.png"
              alt="logo"
              style={{ marginRight: "10px", width: "80px", height: "80px", borderRadius: "40px" }}
            />
            <div
              style={{
                color: "#ffffff",
                fontSize: "38px",
                fontFamily: "Source Sans Pro",
                fontWeight: 700,
                lineHeight: "75px",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              Welcome to Samai Mocktest
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "22px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 700,
                  lineHeight: "45px",
                  textAlign: "center",
                }}
              >
                Practise
              </div>
              <DoubleArrow
                color="#ffffff"
                htmlColor="#ffffff"
                sx={{ alignSelf: "center", marginLeft: 4, marginRight: 4 }}
              />
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "22px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 700,
                  lineHeight: "45px",
                  textAlign: "center",
                }}
              >
                Analyse
              </div>
              <DoubleArrow
                color="#ffffff"
                htmlColor="#ffffff"
                sx={{ alignSelf: "center", marginLeft: 4, marginRight: 4 }}
              />
              <div
                style={{
                  color: "#ffffff",
                  fontSize: "22px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 700,
                  lineHeight: "45px",
                  textAlign: "center",
                }}
              >
                Improve
              </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", marginTop: "8px" }}>
              <button
                style={{
                  cursor: "pointer",
                  width: "130px",
                  height: "48px",
                  padding: "0px 8px",
                  border: "0",
                  boxSizing: "border-box",
                  borderRadius: "24px",
                  opacity: 0.48,
                  backgroundColor: "#ffffff",
                  color: "#161616",
                  fontSize: "18px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 700,
                  lineHeight: "25px",
                  outline: "none",
                }}
                onClick={() => {
                  navigate("/signin");
                }}
              >
                {"sign in"}
              </button>
              <button
                style={{
                  cursor: "pointer",
                  width: "130px",
                  height: "48px",
                  padding: "0px 8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "12px",
                  border: "0",
                  boxSizing: "border-box",
                  borderRadius: "24px",
                  backgroundColor: "#2f667f",
                  color: "#ffffff",
                  fontSize: "18px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 700,
                  lineHeight: "25px",
                  outline: "none",
                  marginLeft: "20px",
                }}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                <span>{"sign up"}</span>
                <CloudDoneOutlined
                  color="#ffffff"
                  htmlColor="#ffffff"
                  sx={{ alignSelf: "center" }}
                />
              </button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
