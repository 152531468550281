/* eslint-disable react/jsx-key */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import "./Dashboard.css";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import { MultiLineChart } from "examples/Charts/LineCharts/MultilineChart";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { Button } from "@mui/material";
import { ChecklistRtl, SmartToy } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BlogsCard from "./components/BlogsCard";
import DisplayCards from "./components/DisplayCards";
import CreateQuizNew from "layouts/quiz/CreateQuizNew";
import HeatMapCharts from "examples/Charts/HeatMap/HeatMapCharts";
import { client } from "constants/APIFunctions";
import { auth } from "../../firebase/firebase";
import Lottie from "react-lottie";
import anim1 from "../dashboard/assets/anim1.json";
import anim2 from "../dashboard/assets/anim2.json";
import anim3 from "../dashboard/assets/anim3.json";
import { MapTwoTone } from "@material-ui/icons";
import Menu from "@mui/icons-material/Menu";
import { Helmet } from "react-helmet";
import { subject_list } from "layouts/quiz/data/Precacheddata";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: anim1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: anim2,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptions3 = {
  loop: true,
  autoplay: true,
  animationData: anim3,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const navigate = useNavigate();
  const [userdata, setuserdata] = useState();
  const [subjectData, setsubjectData] = useState();
  const allExams = [
    {
      exam_id: "82465653-9d4c-4e52-95a6-89d3f7b73158",
      created_date: "2024-11-05T18:30:13.990000",
      exam_image_url:
        "https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/a284120fa561fbe7de4be09771826d58-1140x476%20(1).jpg?alt=media&token=6424ba13-04af-482b-a857-445304fce73c",
      exam_name: "KCET",
      exam_status: "active",
      exam_type: "ACADEMIC",
      is_active: true,
      is_live: true,
      is_mains_available: false,
      is_ncert_available: false,
      is_pyq_available: true,
      mains_exam_id: null,
      exam_marks_per_question: "1",
      exam_negative_mark: "0",
      available_languages: ["English"],
    },
    {
      exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
      created_date: "2024-02-25T10:56:11.551000",
      exam_image_url: "https://scontimedia.s3.amazonaws.com/scontimedia%2F1713707539162",
      exam_name: "UPSC",
      exam_status: "active",
      exam_type: "CIVIL_SERVICE",
      is_active: true,
      is_live: true,
      is_mains_available: true,
      is_ncert_available: false,
      is_pyq_available: true,
      mains_exam_id: "14b0fbae-4f58-4567-8328-2eb57f5cdbcd",
      exam_marks_per_question: "2",
      exam_negative_mark: "0.66",
      available_languages: ["English", "Hindi", "Kannada"],
    },
    {
      exam_id: "2df8f075-e95a-4126-a80d-7a68b7e4c31e",
      created_date: "2024-04-21T17:47:09.903000",
      exam_image_url: "https://scontimedia.s3.amazonaws.com/scontimedia%2F1713707620893",
      exam_name: "NEET",
      exam_status: "active",
      exam_type: "ACADEMIC",
      is_active: true,
      is_live: true,
      is_mains_available: false,
      is_ncert_available: false,
      is_pyq_available: true,
      mains_exam_id: null,
      exam_marks_per_question: "4",
      exam_negative_mark: "1.00",
      available_languages: ["English"],
    },
    {
      exam_id: "7a466d6d-2624-4858-b097-ceb7b0058cdb",
      created_date: "2024-04-21T17:47:09.899000",
      exam_image_url: "https://scontimedia.s3.amazonaws.com/scontimedia%2F1713707580962",
      exam_name: "KPSC",
      exam_status: "active",
      exam_type: "CIVIL_SERVICE",
      is_active: true,
      is_live: true,
      is_mains_available: false,
      is_ncert_available: false,
      is_pyq_available: true,
      mains_exam_id: null,
      exam_marks_per_question: "2",
      exam_negative_mark: "0.5",
      available_languages: ["English", "Kannada"],
    },
  ];
  const [blogsData, setBlogsData] = useState([
    {
      source_url:
        "https://www.insightsonindia.com/2023/11/21/a-guide-on-mastering-current-affairs-for-upsc-civil-services-exam-insights-ias-current-affairs/",
      image_url:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnV2uH1OpIewIRU9E-zuH6ftJN7Z6Y7caktg&usqp=CAU",
      heading:
        "A Guide on Mastering Current Affairs for UPSC Civil Services Exam | Insights IAS Current Affairs",
      description:
        "Preparing for the civil services examinations requires a holistic approach, with a keen understanding of the dynamic interplay between current affairs and the static components of the syllabus. Questions often link static content with current events. At Insights IAS, we recognize the pivotal role current affairs play in shaping success at every stage of the UPSC examination – from Prelims and Mains to the crucial Interview. In this guide, we unveil a strategic approach to navigating Insights IAS Current Affairs.",
      source_name: "Union Public Service Commission (UPSC)",
    },
    {
      source_url: "https://www.insightsonindia.com/2023/06/06/failure-a-one-sided-love-story/",
      image_url:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPoVsGY4EbCASOxsGjXpPp8avoqZY8ddNKnA&usqp=CAUg",
      heading: "FAILURE: A ONE-SIDED LOVE STORY",
      description:
        "Life is like a ‘series’ of successive seasons with ‘episodes’ marked by myriad emotions such as happiness, suspense, grief, anger at the unfairness of it all, momentary joys, small and big wins, and sometimes massive failures. It is said that success is just a happy interval between 2 failures. This underscores the ephemeral nature of the 2 things that impact us most in life: Success and Failures! ",
      source_name: "InsightsIAS",
    },
    {
      source_url:
        "https://www.insightsonindia.com/2023/04/13/chatgpts-insights-on-how-insights-ias-can-help-you-succeed-in-upsc-cse/",
      image_url:
        "https://www.insightsonindia.com/wp-content/uploads/2023/04/1-chatGPT-1024x538.jpg",
      heading: "ChatGPT’s Insights on How Insights IAS Can Help You Succeed in UPSC CSE!",
      description:
        "You may have already utilized it to ask mundane/interesting/intriguing questions or to aid in/simplify your UPSC Prep(we will write a post on how to utilize its capabilities well soon!). We are quite sure it would have left you astounded by the quality and quickness of its responses!",
      source_name: "The Hindu",
    },
    {
      source_url:
        "https://www.insightsonindia.com/2023/02/21/announcementtimetable-of-initiatives-posted-on-our-website-for-upsc-cse-preparation/",
      image_url:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyU-dv84cv7UGq2a7IrfMiab6HGMfqEgXqVA&usqp=CAU",
      heading: "Timetable of Initiatives posted on our website for UPSC CSE Preparation!",
      description:
        "Insights IAS always stays true to its tagline: ‘Simplifying IAS Exam Preparation’! We post several initiatives for free on our website to empower you to crack the exam through smart study and application of knowledge gained. But another important factor needed in order to clear the exam is CONSISTENCY and DISCIPLINE!",
      source_name: "Drishti IAS",
    },
    {
      source_url:
        "https://www.insightsonindia.com/2023/02/07/the-power-of-practice-understand-how-ignoring-practice-is-costing-your-success/",
      image_url:
        "https://www.insightsonindia.com/wp-content/uploads/2023/02/Practice-image-1024x1024.png",
      heading:
        "The Power of Practice – understand how ignoring practice is costing your success! by Vinay Sir",
      description:
        "In the context of preparing for the UPSC Civil Services exam, “hard work” is often equated with the quantity of books read and the frequency with which they have been read. The ability to take effective notes is also considered as a factor contributing to the definition of “hard work.” However, this approach is incomplete and fails to capture the essence of what is required to succeed in the exam.",
      source_name: "Civils Daily",
    },
    {
      source_url:
        "https://www.insightsonindia.com/2023/02/03/foreign-policy-quotes-to-ace-your-upsc-mains-exam-i-peninsightsias-prelims-enrichment-notes/",
      image_url:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSllLZRlibIm9AgJXyVFySiCwJsvDlJ3trj-Q&usqp=CAU",
      heading: "Foreign Policy Quotes to Ace Your UPSC Mains Exam",
      description:
        "At Insights IAS, we are committed to providing innovative solutions to help simplify your UPSC CSE preparation. In line with this mission, we are excited to announce the launch of i-PEN, our new Prelims Enrichment Notes initiative.",
      source_name: "IASbaba",
    },
  ]);
  const [previousquiz, setpreviousquiz] = useState();
  const [selectedExam, setSelectedExam] = useState("NEET");
  const findExam = (examName) => {
    return allExams?.find((exam) => exam.exam_name === examName);
  };
  // Handle toggle switch
  const toggleExam = (exam) => {
    setSelectedExam(exam);
    setuserdata({ ...userdata, examPreference: findExam(exam) });
    console.log("updated", { ...userdata, examPreference: findExam(exam) });
    localStorage.setItem(
      "userdata",
      JSON.stringify({ ...userdata, examPreference: findExam(exam) })
    );
  };
  const getSubjects = (exam_id) => {
    axios
      .get(`${CONSTANTS.APIURL}/entity/all`, {
        params: {
          exam_id: exam_id ? exam_id : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
          type: "subject",
          parent_id: exam_id ? exam_id : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
        },
      })
      .then((response) => {
        console.log(response);
        setsubjectData(response.data.entities);
        // localStorage.setItem(
        //   exam_id ? exam_id : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
        //   JSON.parse(response.data.subjects)
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    setuserdata(items);
    var userdata = items;
    const exam_id =
      userdata && userdata?.examPreference
        ? userdata?.examPreference?.exam_id
          ? userdata?.examPreference?.exam_id
          : "813b6e33-91ff-49f0-8e08-2f479c65e4e1"
        : "813b6e33-91ff-49f0-8e08-2f479c65e4e1";

    const exam_name =
      userdata && userdata?.examPreference
        ? userdata?.examPreference?.exam_name
          ? userdata?.examPreference?.exam_name
          : "NEET"
        : "NEET";
    setSelectedExam(exam_name);
    console.log("exam preferred", exam_id);
    getSubjects(exam_id);
    client
      .get("/api/test/get_tests_of_user", {
        params: {
          user_id: auth.currentUser
            ? auth.currentUser.uid
            : userdata?.user_id
            ? userdata?.user_id
            : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
        },
      })
      .then((response) => {
        setpreviousquiz(response.data.test_list);
      })
      .catch((err) => {});
  }, []);
  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />

        <title>SAMAI - AI-Powered Mock Tests for UPSC Aspirants</title>
        <meta
          name="description"
          content="FREE UPSC, KPSC, and UPPSC Mock Tests with AI. Solve Previous Year Questions Subject Wise."
        />

        <meta
          name="keyword"
          content="Free UPSC mock test online, UPSC mock test series, UPSC Civil Services Exam Mock Tests, UPSC Preliminary Test Series, UPSC Mains Test Series, KPSC Preliminary Mock Tests, NEET Entrance Exam Mock Tests, NEET practice questions, NEET sample tests, D-cet Entrance Exam Mock Tests, UPSC GS Mock Tests, UPSC CSE Mock Tests, KPSC GS Mock Tests, NEET Biology Mock Tests, NEET Physics Mock Tests, UPSC Current Affairs Mock Tests, UPSC CSAT Mock Tests, UPSC Optional Subject Mock Tests, UPSC Online Practice Tests, KPSC Competitive Exams Mock Tests, NEET Chemistry Mock Tests, UPSC Test Series for Beginners, KPSC Test Series for Beginners, UPSC Free Online Test Series, Mock Tests for UPSC Exams, NEET mock test series, NEET biology mock tests, NEET chemistry mock tests, NEET physics mock tests, NEET previous year papers with solutions, Practice mock test, Previous year question papers with solutions, Chapter-wise mock tests, Subject-wise mock tests, Online test series for competitive exams, Best mock test series for competitive exams, Free online mock tests for government exams, Mock test preparation tips, Best mock test for UPSC online, UPSC online mock test, KPSC online coaching, SAMAI AI-Based test for NEET preparation, Online NEET Test platform, KPSC KAS mock tests, KPSC PSI mock tests,UPSC Mock Test, UPSC Test Series, UPSC Practice, KPSC Mock Test, NEET Mock Test, D-cet Mock Test, UPSC Civil Services, UPSC Prelims, UPSC Mains, KPSC Exams, NEET Test Series, UPSC GS, UPSC CSAT, UPSC Coaching, KPSC Coaching, NEET Preparation, Mock Test Series, Online Mock Test, Free Mock Test, Government Exams, UPPSC Mock Tests, UPPSC, Mock Tests"
        />
        <meta property="og:description" content="FREE AI Mock Tests for UPSC, KPSC - SAMAI" />
        <meta property="og:url" content="https://samai.scontiapp.com/app" />
        <meta property="og:site_name" content="AI MockTest for UPSC-SAMAI" />

        <meta
          property="og:description"
          content="FREE UPSC, KPSC, and UPPSC Mock Tests with AI. Solve Previous Year Questions Subject Wise."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://samai.scontiapp.com/app" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
        <link rel="canonical" href="https://samai.scontiapp.com/app" />
      </Helmet>
      {window.innerWidth > 768 ? <div /> : <DashboardNavbar />}
      {/* <div className="page-welcome-text-container">
        <span className="page-welcome-text page-welcome-text-name">
          Welcome {userdata?.full_name}
        </span>
        <div>
          <span className="page-welcome-text page-welcome-text-tagline">
            {userdata?.interested_exams ? userdata?.interested_exams[0].exam_name : "UPSC"}: Your
            dream, our AI expertise.
          </span>
        </div>
      </div> */}
      <div
        className="page-welcome-text-container"
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        {/* Welcome text */}
        <div>
          <span className="page-welcome-text page-welcome-text-name">
            Welcome {userdata?.full_name}
          </span>
          <div>
            <span className="page-welcome-text page-welcome-text-tagline">
              {userdata?.examPreference ? userdata?.examPreference?.exam_name : "UPSC"}: Your dream,
              our AI expertise.
            </span>
          </div>
        </div>

        {/* Toggle Switch */}
        <div className="exam-toggle-container" style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              border: "1px solid #ddd",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            {/* NEET Tab */}
            <button
              onClick={() => toggleExam("NEET")}
              style={{
                padding: "8px 16px",
                border: "none",
                background: selectedExam === "NEET" ? "#E0EBFF" : "#fff",
                color: selectedExam === "NEET" ? "#4636FF" : "#555",
                fontWeight: selectedExam === "NEET" ? "bold" : "normal",
                cursor: "pointer",
                outline: "none",
                transition: "background 0.3s ease",
              }}
            >
              NEET
            </button>

            {/* CET Tab */}
            <button
              onClick={() => toggleExam("KCET")}
              style={{
                padding: "8px 16px",
                border: "none",
                background: selectedExam === "KCET" ? "#E0EBFF" : "#fff",
                color: selectedExam === "KCET" ? "#4636FF" : "#555",
                fontWeight: selectedExam === "KCET" ? "bold" : "normal",
                cursor: "pointer",
                outline: "none",
                transition: "background 0.3s ease",
              }}
            >
              KCET
            </button>
          </div>
        </div>
      </div>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        // style={{ paddingInline: 10 }}
      >
        <Grid item xs={12} md={12} lg={12}>
          <MDBox>
            <CreateQuizNew />
          </MDBox>
        </Grid>
      </Grid>

      <MDBox py={3}>
        {previousquiz && previousquiz?.length > 0 ? (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="Test Analysis"
                    description="Explore subject popularity through test attendance. See which subjects attract
                      the most test-takers, guiding resource allocation and educational focus."
                    date="updated now"
                    chart={reportsBarChartData}
                    subject={subjectData}
                    exam_name={
                      userdata?.interested_exams && userdata?.interested_exams != "N/A"
                        ? JSON.parse(userdata?.interested_exams)?.exam_name
                        : "UPSC"
                    }
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  {/* <ReportsLineChart
                    color="success"
                    title="Score Analysis"
                    description="Track your performance across tests. Identify trends, strengths, and
                      weaknesses to tailor teaching strategies and study plans effectively."
                    date="updated now"
                    chart={sales}
                    subject={subjectData}
                  /> */}
                  <MultiLineChart
                    color="success"
                    title="Score Rate Analysis"
                    description="Track your performance across tests. Identify trends, strengths, and
                      weaknesses to tailor teaching strategies and study plans effectively."
                    date="updated now"
                    chart={sales}
                    subject={subjectData}
                    exam_name={
                      userdata?.interested_exams && userdata?.interested_exams != "N/A"
                        ? JSON.parse(userdata?.interested_exams)?.exam_name
                        : "UPSC"
                    }
                    exam_id={
                      userdata?.interested_exams && userdata?.interested_exams != "N/A"
                        ? JSON.parse(userdata?.interested_exams)?.exam_id
                        : "813b6e33-91ff-49f0-8e08-2f479c65e4e1"
                    }
                  />
                </MDBox>
              </Grid>
              {/*   <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <HeatMapCharts
                    color="dark"
                    title="Subject-wise Analysis"
                    description="Visualize performance patterns across subjects and topics. Identify areas of
                      excellence and improvement for targeted interventions and curriculum
                      adjustments."
                    date="just updated"
                    chart={tasks}
                  />
                </MDBox>
              </Grid> */}
            </Grid>
          </MDBox>
        ) : (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <div
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#ffffff",
                      border: "2px solid #edc967",
                    }}
                  >
                    <Lottie options={defaultOptions1} height={200} width={"80%"} />
                    <p
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        margin: "20px 0",
                        padding: "8px",
                        fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
                      }}
                    >
                      Explore subject popularity through test attendance. See which subjects attract
                      the most test-takers, guiding resource allocation and educational focus.
                    </p>
                  </div>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  <div
                    style={{
                      width: "100%",
                      height: "400px",
                      borderRadius: "8px",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#ffffff",
                      border: "2px solid #edc967",
                    }}
                  >
                    <Lottie options={defaultOptions2} height={200} width={"80%"} />
                    <p
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "500",
                        margin: "20px 0",
                        padding: "8px",
                        fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
                      }}
                    >
                      Track student performance across tests. Identify trends, strengths, and
                      weaknesses to tailor teaching strategies and study plans effectively.
                    </p>
                  </div>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
