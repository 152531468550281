import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Typography } from "@mui/material";

const UserProfile = () => {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <Typography>UserProfile Page</Typography>
      </DashboardLayout>
    </>
  );
};

export default UserProfile;
